import { Component, DestroyRef, inject, Input } from '@angular/core';
import {
  animate,
  animation,
  AnimationReferenceMetadata,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { MenuItem } from '../../../app.component';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgOptimizedImage } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface AnimationParams {
  menuWidth: string;
  animationStyle: string;
}

const animationParams: AnimationParams = {
  menuWidth: '500px',
  animationStyle: '300ms ease',
};
export const SIDEBAR_OPEN_ANIMATION: AnimationReferenceMetadata = animation([
  style({ right: '-{{menuWidth}}' }),
  query('.menu-item', [style({ transform: 'translateX({{menuWidth}})' })]),
  sequence([
    animate('200ms', style({ right: '0' })),
    query('.menu-item', [
      stagger(50, [
        animate('{{animationStyle}}', style({ transform: 'none' })),
      ]),
    ]),
  ]),
]);

export const SIDEBAR_CLOSE_ANIMATION: AnimationReferenceMetadata = animation([
  style({ right: '0' }),
  query('.menu-item', [style({ transform: 'none' })]),
  sequence([
    query('.menu-item', [
      stagger(-50, [
        animate(
          '{{animationStyle}}',
          style({ transform: 'translateX({{menuWidth}})' }),
        ),
      ]),
    ]),
    animate('200ms', style({ right: '-{{menuWidth}}' })),
  ]),
]);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [
    trigger('sideMenu', [
      transition(':enter', [
        useAnimation(SIDEBAR_OPEN_ANIMATION, {
          params: {
            ...animationParams,
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(SIDEBAR_CLOSE_ANIMATION, {
          params: {
            ...animationParams,
          },
        }),
      ]),
    ]),
  ],
  standalone: true,
  imports: [RouterModule, MatIconModule, NgOptimizedImage],
})
export class HeaderComponent {
  private readonly detroyRef: DestroyRef = inject(DestroyRef);
  public bIsMobileMenuOpen: boolean = false;
  @Input()
  public arMenuItems!: Array<MenuItem>;

  constructor(private readonly router: Router) {
    this.router.events
      .pipe(takeUntilDestroyed(this.detroyRef))
      .subscribe(() => {
        this.bIsMobileMenuOpen = false;
      });
  }

  public trackByIndex(index: number): number {
    return index;
  }
}
