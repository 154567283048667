<div class="w-full flex justify-end my-8"></div>
<table
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="announceSortChange($event)"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gebäude</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="squareMeters">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Quadratmeter</th>
    <td mat-cell *matCellDef="let element">{{ element.squareMeters }}</td>
  </ng-container>

  <ng-container matColumnDef="electricityKWH">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Strom</th>
    <td mat-cell *matCellDef="let element">{{ element.electricityKWH }}</td>
  </ng-container>

  <ng-container matColumnDef="gasKWH">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gas</th>
    <td mat-cell *matCellDef="let element">{{ element.gasKWH }}</td>
  </ng-container>

  <ng-container matColumnDef="wasteKg">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Abfall</th>
    <td mat-cell *matCellDef="let element">{{ element.wasteKg }}</td>
  </ng-container>

  <ng-container matColumnDef="waterL">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Wasser</th>
    <td mat-cell *matCellDef="let element">
      {{ element.waterL }}
    </td>
  </ng-container>
  <ng-container matColumnDef="beveragesKg">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Verpflegung</th>
    <td mat-cell *matCellDef="let element">
      {{ element.foodKg }}
    </td>
  </ng-container>
  <ng-container matColumnDef="drinksL">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Getränke</th>
    <td mat-cell *matCellDef="let element">
      {{ element.beveragesL }}
    </td>
  </ng-container>
  <ng-container matColumnDef="miscellaneous">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sonstiges</th>
    <td mat-cell *matCellDef="let element">
      {{ element.miscellaneous }}
    </td>
  </ng-container>
  <ng-container matColumnDef="co2Consumption">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Co2 Verbrauch</th>
    <td mat-cell *matCellDef="let element">
      {{ element | climateCalculationBuilding | async | number }} kg
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
