import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app-routing';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { ApiModule } from './app/core/swagger/api.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { jwtInterceptor } from './app/core/interceptors/jwt-interceptor';
import { NgxsModule } from '@ngxs/store';
import { loadingInterceptor } from './app/core/interceptors/loading-interceptor';
import { environment } from './environments/environment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './app/transloco-loader';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withInterceptors([loadingInterceptor, jwtInterceptor])),
    importProvidersFrom(
      ApiModule.forRoot({ rootUrl: environment.apiPath }),
      MatSnackBarModule,
      NgxsModule.forRoot(),
      NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate' }),
    ),
    provideTransloco({
      config: {
        availableLangs: ['de', 'es'],
        defaultLang: 'de',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
}).catch((err) => console.error(err));
