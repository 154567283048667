import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router, RouterEvent, RouterModule } from '@angular/router';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { registerLocaleData } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { LoadingService } from './core/services/loading.service';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    FooterComponent,
    RouterModule,
    HeaderComponent,
    NgxSpinnerModule
],
})
export class AppComponent implements OnInit {
  private readonly detroyRef: DestroyRef = inject(DestroyRef);
  public bShowHead: boolean = false;
  public arMenuItemsHeader: Array<MenuItem> = [];
  public arMenuItemsFooter: Array<MenuItem> = [];

  constructor(
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly ngxSpinnerService: NgxSpinnerService,
  ) {}

  public ngOnInit(): void {
    this.loadingService.isLoading$
      .asObservable()
      .pipe(takeUntilDestroyed(this.detroyRef))
      .subscribe({
        next: (isLoading) => {
          if (isLoading) {
            this.ngxSpinnerService.show();
          } else {
            this.ngxSpinnerService.hide();
          }
        },
      });
    this.router.events
      .pipe(takeUntilDestroyed(this.detroyRef))
      .subscribe((event) => {
        if (event instanceof RouterEvent) {
          this.bShowHead = event.url !== '/' && event.url !== '/admin/login';
        }
      });
  }
}

export interface MenuItem {
  sLink: string;
  sTitle: string;
  arSubMenu?: Array<{ sLink: string; sTitle: string }>;
}
