@if (this.generalForm.valueChanges | async; as climateEntry) {
  <div class="fixed w-full z-50 bg-light-grey p-[1rem] justify-center flex">
    <div>
      Co2 Verbrauch:
      {{
        climateEntry | climateCalculation | async | number: "1.2-2" : "de-DE"
      }}
      kg
    </div>
  </div>
}
<div
  *transloco="let t"
  class="pt-[5rem] w-full items-center flex justify-center p-[1rem]"
>
  <form [formGroup]="generalForm" appInvalidControlScroll>
    <div class="max-w-[80rem] flex-wrap flex gap-y-4 w-full">
      <mat-card class="w-full">
        <mat-card-header>
          <mat-card-title>{{ climateTemplate.sTitle }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-chip-listbox>
            <mat-chip
              >{{ t("departmentTypes." + climateTemplate.eDepartment!) }}
            </mat-chip>
            <mat-chip
              >{{ t("sportsTypes." + climateTemplate.eSport!) }}
            </mat-chip>
          </mat-chip-listbox>
        </mat-card-content>
      </mat-card>
      <mat-card class="w-full">
        <mat-card-header>
          <mat-card-title>Allgemeine Daten</mat-card-title>
        </mat-card-header>
        <mat-card-content class="flex space-y-3 mt-4">
          <mat-checkbox class="w-full" formControlName="canceled"
            >Veranstaltung ist ausgefallen
          </mat-checkbox>
          <mat-form-field class="w-full">
            <mat-label>Veranstatlungszeitraum</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                formControlName="dtStart"
                matStartDate
                placeholder="Beginn"
              />
              <input formControlName="dtEnd" matEndDate placeholder="Ende" />
            </mat-date-range-input>
            <mat-hint>TT.MM.JJJJ – TT.MM.JJJJ</mat-hint>
            <mat-datepicker-toggle
              [for]="picker"
              matIconSuffix
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Personen</mat-label>
            <input
              formControlName="iPersons"
              matInput
              placeholder="Anzahl der Personen"
              type="number"
            />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card class="w-full">
        <mat-card-header>
          <mat-card-title>Mobilität An- und Abreise</mat-card-title>
        </mat-card-header>
        <mat-card-content class="flex space-y-3 mt-4">
          <div formArrayName="transportations">
            @for (
              transportation of getTransportations().controls;
              track trackByIndex($index)
            ) {
              <div [formGroupName]="$index">
                @if (!$first) {
                  <mat-divider class="m-2"></mat-divider>
                }
                <mat-card class="m-1">
                  <mat-card-header class="justify-end">
                    <button
                      (click)="removeTransportation($index, $event)"
                      class="close-button"
                      mat-icon-button
                    >
                      <mat-icon class="close-icon" color="warn">close</mat-icon>
                    </button>
                  </mat-card-header>
                  <mat-card-content>
                    <mat-form-field class="w-full">
                      <mat-label>Art</mat-label>
                      <mat-select formControlName="eType" #transportationType>
                        @for (
                          transportationTyp of transportationTypes;
                          track trackByIndex($index)
                        ) {
                          <mat-option [value]="transportationTyp">
                            {{ t("transportationTypes." + transportationTyp) }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                      <mat-label>{{
                        labelForTransportation(transportationType.value)
                      }}</mat-label>
                      <input formControlName="iNumber" matInput type="number" />
                    </mat-form-field>
                    <mat-form-field class="w-full">
                      <mat-label>Strecke in km</mat-label>
                      <input
                        formControlName="iDistance"
                        matInput
                        placeholder="Strecke in km"
                        type="number"
                      />
                    </mat-form-field>
                  </mat-card-content>
                </mat-card>
              </div>
            }
          </div>
          <button
            (click)="addTransportation($event)"
            class="w-full"
            color="primary"
            mat-stroked-button
          >
            <mat-icon>add</mat-icon>
            Transport Hinzufügen
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card class="w-full">
        <mat-card-header>
          <mat-card-title>Übernachtungen</mat-card-title>
        </mat-card-header>
        <mat-card-content
          class="flex space-y-3 mt-4"
          formArrayName="accommodations"
        >
          @for (
            accommodation of getAccommodations().controls;
            track trackByIndex($index)
          ) {
            <div [formGroupName]="$index">
              @if (!$first) {
                <mat-divider class="m-2"></mat-divider>
              }
              <mat-card class="m-1">
                <mat-card-header class="justify-end">
                  <button
                    (click)="removeAccommodation($index, $event)"
                    class="close-button"
                    mat-icon-button
                  >
                    <mat-icon class="close-icon" color="warn">close</mat-icon>
                  </button>
                </mat-card-header>
                <mat-card-content>
                  <mat-form-field class="w-full">
                    <mat-label>Art</mat-label>
                    <mat-select formControlName="eType">
                      @for (
                        accommodationTyp of accommodationTypes;
                        track trackByIndex($index)
                      ) {
                        <mat-option [value]="accommodationTyp">
                          {{ t("accommodationTypes." + accommodationTyp) }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-full">
                    <mat-label>Personen</mat-label>
                    <input
                      formControlName="iPersons"
                      matInput
                      placeholder="Personen"
                      type="number"
                    />
                  </mat-form-field>
                  <mat-form-field class="w-full">
                    <mat-label>Nächte</mat-label>
                    <input
                      formControlName="iNights"
                      matInput
                      placeholder="Nächte"
                      type="number"
                    />
                  </mat-form-field>
                </mat-card-content>
              </mat-card>
            </div>
          }
          <button
            class="w-full"
            (click)="addAccommodation($event)"
            color="primary"
            mat-stroked-button
          >
            <mat-icon>add</mat-icon>
            Übernachtung hinzufügen
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card class="w-full">
        <mat-card-header>
          <mat-card-title>Verpflegung</mat-card-title>
        </mat-card-header>
        <mat-card-content class="flex space-y-3 mt-4">
          <mat-form-field class="w-full">
            <mat-label>Mahlzeiten ohne Fleisch</mat-label>
            <input
              formControlName="iVegetarians"
              matInput
              placeholder="Vegetarier"
              type="number"
            />
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Mahlzeiten mit Fleisch</mat-label>
            <input
              formControlName="iPersonsWithMeat"
              matInput
              placeholder="Personen mit Fleisch"
              type="number"
            />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <button
        (click)="onSubmit()"
        class="w-full"
        color="primary"
        mat-stroked-button
      >
        Speichern
      </button>
    </div>
  </form>
</div>
