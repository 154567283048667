import {
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appInvalidControlScroll]',
  standalone: true,
})
export class InvalidControlScrollDirective {
  private readonly detroyRef: DestroyRef = inject(DestroyRef);
  private get containerEl(): Window {
    return window;
  }

  constructor(
    private readonly el: ElementRef,
    private readonly formGroupDir: FormGroupDirective,
  ) {}

  @HostListener('ngSubmit')
  private onSubmit(): void {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('.ng-invalid');

    this.containerEl.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(this.containerEl, 'scroll')
      .pipe(debounceTime(100), take(1), takeUntilDestroyed(this.detroyRef))
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset: number = 150;
    const controlElTop: number = controlEl.getBoundingClientRect().top;

    const absoluteControlElTop: number = controlElTop + window.scrollY;

    return absoluteControlElTop - labelOffset;
  }
}
