import { inject, Pipe, PipeTransform } from '@angular/core';
import { ClimateEntriesQueries } from '../../states/climateEntries/climate-entries.queries';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClimateCalculationService } from '../services/climate-calculation.service';
import { BuildingDto } from '../../core/swagger/models/building-dto';

@Pipe({
  name: 'climateCalculationBuilding',
  standalone: true,
})
export class ClimateCalculationBuildingPipe implements PipeTransform {
  private readonly climateCalculationKeysQueries: ClimateEntriesQueries =
    inject(ClimateEntriesQueries);

  public transform(building: BuildingDto): Observable<number> {
    return this.climateCalculationKeysQueries.climateCalculationKeys$.pipe(
      map((climateCalculationKeys) =>
        climateCalculationKeys
          ? ClimateCalculationService.calculateBuildings(
              [building],
              climateCalculationKeys,
            )
          : 0,
      ),
    );
  }
}
