import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  private totalRequests: number = 0;

  constructor() {}

  public show(): void {
    this.totalRequests++;
    if (this.totalRequests === 1) {
      this.isLoading$.next(true);
    }
  }

  public hide(): void {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.isLoading$.next(false);
    }
  }
}
