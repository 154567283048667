import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { ClimateCalculationKeys } from '../../core/swagger/models/climate-calculation-keys';
import { ResolveFn } from '@angular/router';
import { FetchClimateCalculationKeys } from '../../states/climateEntries/climate-entries.actions';

export const resolveClimateCalculationKeys: ResolveFn<
  ClimateCalculationKeys
> = () => {
  const store: Store = inject(Store);
  return store.dispatch(new FetchClimateCalculationKeys());
};
