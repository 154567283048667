<footer class="bg-[#f7f7f7]">
  <div class="m-5 border-bottom flex flex-wrap py-5">
    <div class="w-full md:w-1/4 mt-3">
      <div class="w-full md:text-center">
        <img
          class="mx-auto"
          ngSrc="/assets/logos/logo.jpg"
          alt="Trading for Professional"
          height="70"
          width="121"
        />
        <div class="pl-[0.5rem] pt-3 text-textColor font-bold">
          Klimabilanzierung
        </div>
      </div>
    </div>
    <div class="w-full md:w-3/4">
      <div class="w-full flex flex-wrap"></div>
    </div>
  </div>
  <div class="p-5 pt-0 text-base">© 2022 DAV Hersbruck</div>
</footer>
