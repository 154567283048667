import { Component, Input } from '@angular/core';
import { MenuItem } from '../../../app.component';
import { RouterLink } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [RouterLink, NgOptimizedImage],
})
export class FooterComponent {
  @Input()
  public arMenuItems!: Array<MenuItem>;

  public trackByIndex(index: number): number {
    return index;
  }
}
