import { Routes } from '@angular/router';
import { ClimateEntryComponent } from './climate-entry/climate-entry.component';
import { ClimateTokenComponent } from './climate-token/climate-token.component';
import { JWTTokenService } from './core/services/jwttoken-service.service';
import { LocalStorageService } from './core/services/local-storage-service.service';
import { importProvidersFrom } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ClimateEntriesState } from './states/climateEntries/climate-entries.state';
import { isAuthorized } from './core/guards/authorize-guard';
import { resolveClimateCalculationKeys } from './shared/resolver/climate-calculation-keys-resolver';
import { resolveClimateTemplate } from './climate-entry/climate-entry-resolver';
import { resolveClimateEntries } from './+admin/overview/climate-entries-resolver';
import { CapturesComponent } from './+admin/overview/captures/captures.component';
import { BalanceComponent } from './+admin/overview/balance/balance.component';
import { BuildingsComponent } from './+admin/overview/buildings/buildings.component';

export const APP_ROUTES: Routes = [
  { path: '', component: ClimateTokenComponent },
  {
    path: 'climateEntry/:sToken',
    component: ClimateEntryComponent,
    providers: [
      importProvidersFrom(NgxsModule.forFeature([ClimateEntriesState])),
    ],
    resolve: {
      climateTemplate: resolveClimateTemplate,
      climateCalculationKeys: resolveClimateCalculationKeys,
    },
  },
  {
    path: 'admin',
    providers: [JWTTokenService, LocalStorageService],
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('./+admin/+login/login.component').then(
            (m) => m.LoginComponent,
          ),
      },
      {
        path: '',
        loadComponent: () =>
          import('./+admin/overview/overview.component').then(
            (m) => m.OverviewComponent,
          ),
        canMatch: [isAuthorized],
        resolve: {
          climateEntries: resolveClimateEntries,
          climateCalculationKeys: resolveClimateCalculationKeys,
        },
        providers: [
          importProvidersFrom(NgxsModule.forFeature([ClimateEntriesState])),
        ],
        children: [
          { path: 'captures', component: CapturesComponent },
          { path: 'facilities', component: BuildingsComponent },
          { path: 'balance', component: BalanceComponent },
          { path: 'settings', component: BalanceComponent },
        ],
      },
    ],
  },
];
