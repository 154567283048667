import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from './local-storage-service.service';

@Injectable()
export class JWTTokenService {
  private decodedToken: { [key: string]: string } = {};

  constructor(private readonly localStorageService: LocalStorageService) {}

  public decodeToken(): void {
    const sToken: string | null = this.localStorageService.get('jwt');
    if (sToken) {
      this.decodedToken = jwtDecode(sToken);
    }
  }

  public getUser(): string | null {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['sEmail'] : null;
  }

  public getExpiryTime(): string | null {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['exp'] : null;
  }

  public isTokenExpired(): boolean {
    const expiryTime: string | null = this.getExpiryTime();
    return expiryTime
      ? 1000 * Number.parseInt(expiryTime, 10) - Date.now() < 5000
      : false;
  }
}
