<div class="w-full justify-center items-center flex mt-[15rem]">
  <div class="max-w-6xl flex flex-wrap">
    <div
      [routerLink]="'/'"
      class="hidden xl:block w-1/2 h-[400px] border-r border-accent pr-[100px] pt-[30px] cursor-pointer"
    >
      <div class="flex flex-wrap justify-center items-center">
        <img
          alt="Dav Herbsruck"
          height="70"
          ngSrc="assets/logos/logo.jpg"
          width="121"
        />
      </div>
      <div class="py-8">
        <div class="w-full border-t border-accent"></div>
      </div>
      <div class="text-accent text-2xl">Klimabilanzierung 2023</div>
      <div class="mt-6">
        Der DAV Hersbruck bilanziert im Jahr 2023 seine CO2 Ausgaben.
      </div>
    </div>
    <div class="xl:border-l xl:border-accent xl:pl-[100px] pt-[30px]">
      <div class="mb-[3rem] xl:hidden">
        <img
          alt="Dav Hersbruck"
          class="mx-auto"
          height="70"
          ngSrc="assets/logos/logo.jpg"
          width="121"
        />
      </div>
      <div class="text-accent text-4xl">Klimabilanz</div>
      <form [formGroup]="loginForm">
        <div class="row">
          <div class="col-12 mt-[50px]">
            <mat-form-field class="w-[400px]">
              <mat-label>Klimatoken</mat-label>
              <input
                class="w-[400px]"
                formControlName="sToken"
                id="sToken"
                matInput
                placeholder="Klimatoken"
                type="text"
              />
            </mat-form-field>
          </div>

          <button
            mat-stroked-button
            class="w-[400px]"
            color="primary"
            (click)="navigateToClimateEntry()"
            (keydown.enter)="navigateToClimateEntry()"
          >
            Veranstaltung eintragen
          </button>
        </div>
      </form>
      <div
        class="text-[#212221] hover:text-accent w-[400px] pt-[30px] text-center cursor-pointer text-sm"
      >
        <a routerLink="/admin/login">Adminbereich</a>
      </div>
    </div>
  </div>
</div>
