<header class="sticky top-0 z-50 bg-white">
  <div
    class="h-[6rem] flex-wrap overflow-hidden px-[6rem] border-b border-b-[#16161629] hidden xl:flex"
  >
    <div class="w-1/4 overflow-hidden flex items-center">
      <img
        alt="Dav Hersbruck"
        class="top-auto"
        ngSrc="assets/logos/logo.jpg"
        height="70"
        width="121"
        priority
      />
      <div class="pl-[1rem] text-textColor">Klimabilanzierung</div>
    </div>

    <div
      class="w-3/4 overflow-hidden flex items-center space-x-16 place-content-end text-textColor"
    >
      @for (arMenuItem of arMenuItems; track trackByIndex($index)) {
        <div>
          <a [routerLink]="[arMenuItem.sLink]" class="hover:text-[#96a4b0]">{{
            arMenuItem.sTitle
          }}</a>
        </div>
      }
    </div>
  </div>

  <div
    class="h-[6rem] border-b border-b-[#16161629] justify-center items-center flex xl:hidden"
  >
    <div class="w-[6rem]"></div>
    <div class="w-full flex justify-center items-center">
      <img
        alt="Dav Hersbruck"
        class="top-auto"
        ngSrc="assets/logos/logo.jpg"
        height="70"
        width="121"
        priority
      />
    </div>
    <div
      (click)="bIsMobileMenuOpen = !bIsMobileMenuOpen"
      (keydown.enter)="bIsMobileMenuOpen = !bIsMobileMenuOpen"
      class="w-[6rem] float-right cursor-pointer"
    ></div>
  </div>
  @if (bIsMobileMenuOpen) {
    <div
      class="fixed w-[20rem] h-[calc(100vh-6rem)] right-0 bottom-0 bg-white overflow-hidden"
    >
      <div class="border-b border-b-[#16161629] p-[1.5rem] font-textColor">
        <a href="/">Startseite</a>
      </div>
      <div class="mt-5 pl-5 space-y-4">
        @for (arMenuItem of arMenuItems; track trackByIndex($index)) {
          <div class="menu-item">
            <a [routerLink]="[arMenuItem.sLink]">{{ arMenuItem.sTitle }}</a>
            @if (arMenuItem.arSubMenu !== undefined) {
              <div class="subnav">
                <div class="hidden">
                  @for (
                    arMenuItem of arMenuItem.arSubMenu;
                    track trackByIndex($index)
                  ) {
                    <div>
                      <a
                        (click)="bIsMobileMenuOpen = !bIsMobileMenuOpen"
                        [routerLink]="[arMenuItem.sLink]"
                        >{{ arMenuItem.sTitle }}</a
                      >
                    </div>
                  }
                </div>
              </div>
            }
            @if (arMenuItem.arSubMenu !== undefined) {
              <div class="hidden cursor-pointer pr-0.5">
                <i class="fas fa-arrow-alt-circle-down"></i>
              </div>
            }
          </div>
        }
      </div>
    </div>
  }
</header>
