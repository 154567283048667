import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './climate-token.component.html',
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    NgOptimizedImage,
  ],
})
export class ClimateTokenComponent {
  public readonly loginForm: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) {
    this.loginForm = this.formBuilder.group({
      sToken: ['', [Validators.required]],
    });
  }

  public navigateToClimateEntry(): void {
    this.router.navigateByUrl(
      '/climateEntry/' + this.loginForm.get('sToken')?.value,
    );
  }
}
