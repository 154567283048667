import { inject } from '@angular/core';
import { JWTTokenService } from '../services/jwttoken-service.service';
import { Router } from '@angular/router';

export const isAuthorized: () => boolean = (): boolean => {
  const jwtService: JWTTokenService = inject(JWTTokenService);
  const router: Router = inject(Router);
  if (jwtService.getUser()) {
    if (!jwtService.isTokenExpired()) {
      return true;
    }
  }
  router.navigateByUrl('/login').then();
  return false;
};
