import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ClimateTemplate } from '../core/swagger/models/climate-template';
import { ApiService } from '../core/swagger/services/api.service';

export const resolveClimateTemplate: ResolveFn<ClimateTemplate> = (
  route: ActivatedRouteSnapshot,
) => {
  const service: ApiService = inject(ApiService);
  const sToken: string | null = route.paramMap.get('sToken');

  return service.climateTemplateSTokenGet({
    sToken: sToken ?? '',
  });
};
