import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, DatePipe, DecimalPipe } from '@angular/common';
import { ClimateCalculationPipe } from '../../../shared/pipes/climate-calculation.pipe';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { ClimateEntriesQueries } from '../../../states/climateEntries/climate-entries.queries';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BuildingDto } from '../../../core/swagger/models/building-dto';
import { ClimateCalculationBuildingPipe } from '../../../shared/pipes/climate-calculation-building.pipe';

@Component({
  selector: 'app-buildings',
  standalone: true,
  imports: [
    AsyncPipe,
    ClimateCalculationPipe,
    DatePipe,
    DecimalPipe,
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatRow,
    MatRowDef,
    MatSort,
    MatSortHeader,
    MatTable,
    MatHeaderCellDef,
    ClimateCalculationBuildingPipe,
  ],
  templateUrl: './buildings.component.html',
})
export class BuildingsComponent implements OnInit, AfterViewInit {
  private readonly detroyRef: DestroyRef = inject(DestroyRef);
  private readonly climateEntriesQueries: ClimateEntriesQueries = inject(
    ClimateEntriesQueries,
  );
  private readonly _liveAnnouncer: LiveAnnouncer = inject(LiveAnnouncer);
  public displayedColumns: Array<string> = [
    'name',
    'squareMeters',
    'electricityKWH',
    'gasKWH',
    'wasteKg',
    'waterL',
    'beveragesKg',
    'drinksL',
    'miscellaneous',
    'co2Consumption',
  ];
  public dataSource: MatTableDataSource<BuildingDto> =
    new MatTableDataSource<BuildingDto>([]);
  @ViewChild(MatSort) public sort: MatSort | undefined;

  public ngOnInit(): void {
    this.climateEntriesQueries.buildings$
      .pipe(takeUntilDestroyed(this.detroyRef))
      .subscribe((buildings) => (this.dataSource.data = buildings));
  }

  public ngAfterViewInit(): void {
    if (this.sort) this.dataSource.sort = this.sort;
  }

  /** Announce the change in sort state for assistive technology. */
  public announceSortChange(sortState: Sort): void {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
