@if (bShowHead) {
  <app-header [arMenuItems]="arMenuItemsHeader"></app-header>
}
<div class="content">
  <router-outlet></router-outlet>
</div>
@if (bShowHead) {
  <app-footer [arMenuItems]="arMenuItemsFooter"></app-footer>
}
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#55ab26"
  type="ball-clip-rotate"
  [fullScreen]="true"
  ><p style="color: white">Laden...</p></ngx-spinner
  >
