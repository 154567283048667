import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.includes('/admin') && !req.url.includes('/admin/login')) {
    // Setting a dummy token for demonstration
    const token: string | null = localStorage.getItem('jwt');
    const headers: HttpHeaders = req.headers.set(
      'Authorization',
      `Bearer ${token}`
    );
    req = req.clone({
      headers,
    });
  }

  return next(req);
};
