import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { AccomodationTyp, TransportationTyp } from '../models/ClimateEntry';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ApiService } from '../core/swagger/services/api.service';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ClimateTemplate } from '../core/swagger/models/climate-template';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { InvalidControlScrollDirective } from '../core/directives/invalid-controll.directive';
import { ClimateCalculationPipe } from '../shared/pipes/climate-calculation.pipe';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClimateEntryDto } from '../core/swagger/models/climate-entry-dto';
import { TranslocoDirective } from '@ngneat/transloco';
import { DateService } from '../shared/services/date.service';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@Component({
  selector: 'app-climate-entry',
  templateUrl: './climate-entry.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatSnackBarModule,
    MatIconModule,
    ReactiveFormsModule,
    MatChipsModule,
    InvalidControlScrollDirective,
    ClimateCalculationPipe,
    TranslocoDirective,
  ],
  providers: [
    FormGroupDirective,
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ],
})
export class ClimateEntryComponent implements OnInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly dateService: DateService = inject(DateService);
  @Input() private readonly sToken: string = '';
  public readonly generalForm: FormGroup = this.formBuilder.group({
    canceled: [],
    dtStart: ['', [Validators.required]],
    dtEnd: ['', [Validators.required]],
    iPersons: ['', [Validators.required]],
    transportations: this.formBuilder.array([]),
    accommodations: this.formBuilder.array([]),
    iVegetarians: [0, [Validators.required]],
    iPersonsWithMeat: [0, [Validators.required]],
  });
  public transportationTypes: Array<string> = Object.values(TransportationTyp);
  public accommodationTypes: Array<string> = Object.values(AccomodationTyp);
  @Input({ required: true })
  public climateTemplate!: ClimateTemplate;

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly matSnackBar: MatSnackBar,
  ) {}

  public ngOnInit(): void {
    this.generalForm.controls['dtStart'].setValue(
      new Date(this.climateTemplate.dtStart!.toString()),
    );
    this.generalForm.controls['dtEnd'].setValue(
      new Date(this.climateTemplate.dtEnd!.toString()),
    );
  }

  public getAccommodations(): FormArray {
    return this.generalForm.get('accommodations') as FormArray;
  }

  public addAccommodation($event: MouseEvent): void {
    $event.preventDefault();
    this.getAccommodations().push(this.emptyAccommodation());
  }

  public removeAccommodation(i: number, $event: MouseEvent): void {
    $event.preventDefault();
    this.getAccommodations().removeAt(i);
  }

  public getTransportations(): FormArray {
    return this.generalForm.get('transportations') as FormArray;
  }

  public addTransportation($event: MouseEvent): void {
    $event.preventDefault();
    this.getTransportations().push(this.emptyTransportation());
  }

  public removeTransportation(i: number, $event: MouseEvent): void {
    $event.preventDefault();
    this.getTransportations().removeAt(i);
  }

  public onSubmit(): void {
    if (this.generalForm.get('canceled')?.value) {
      this.apiService
        .climateEntryCancelSTokenPost({
          sToken: this.sToken,
        })
        .subscribe({
          next: () => {
            this.matSnackBar.open('Vielen Dank für deine Mithilfe!', 'Ok', {});
            this.router.navigateByUrl('/').then();
          },
          error: (error) => {
            console.log(error);
            this.matSnackBar.open(
              'Hier hat leider etwas nicht geklappt, bitte probiere es später noch einmal.',
              'Ok',
              {},
            );
          },
        });
    }
    if (this.generalForm.valid && !this.generalForm.get('canceled')?.value) {
      const dtStart: Date = this.generalForm.get('dtStart')?.value;
      dtStart.setHours(dtStart.getHours() + 4);
      const dtEnd: Date = this.generalForm.get('dtEnd')?.value;
      dtEnd.setHours(dtEnd.getHours() + 4);
      const climateEntry: ClimateEntryDto = {
        sToken: this.sToken,
        canceled: false,
        accommodations: this.generalForm.value.accommodations,
        dtStart: this.dateService.formatDateDTO(dtStart),
        dtEnd: this.dateService.formatDateDTO(dtEnd),
        iPersons: this.generalForm.get('iPersons')?.value,
        transportations: this.generalForm.value.transportations,
      };
      climateEntry.iPersonsWithMeat =
        this.generalForm.get('iPersonsWithMeat')?.value;
      climateEntry.iVegetarians = this.generalForm.get('iVegetarians')?.value;

      this.apiService
        .climateEntryPut({ body: climateEntry })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.matSnackBar.open('Vielen Dank für deine Mithilfe!', 'Ok', {});
            this.router.navigateByUrl('/').then();
          },
          error: (error) => {
            console.log(error);
            this.matSnackBar.open(
              'Hier hat leider etwas nicht geklappt, bitte probiere es später noch einmal.',
              'Ok',
              {},
            );
          },
        });
    }
  }

  public labelForTransportation(transportationType: TransportationTyp): string {
    if (
      transportationType === TransportationTyp.BUS ||
      transportationType === TransportationTyp.PKW_Benzin ||
      transportationType === TransportationTyp.PKW_DIESEL ||
      transportationType === TransportationTyp.PKW_ELECTRO ||
      transportationType === TransportationTyp.PKW_PLUGIN ||
      transportationType === TransportationTyp.DAV_BUS ||
      transportationType === TransportationTyp.VAN
    ) {
      return 'Anzahl Fahrzeuge';
    }
    return 'Anzahl Personen';
  }

  public trackByIndex(index: number): number {
    return index;
  }

  private emptyAccommodation(): FormGroup {
    return this.formBuilder.group({
      eType: ['', [Validators.required]],
      iNights: ['', [Validators.required]],
      iPersons: [this.getPersons(), [Validators.required]],
    });
  }

  private emptyTransportation(): FormGroup {
    return this.formBuilder.group({
      eType: ['', [Validators.required]],
      iDistance: ['', Validators.required],
      iNumber: ['', [Validators.required]],
    });
  }

  private getPersons(): number {
    return this.generalForm.get('iPersons')?.value;
  }
}
